import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { ClickableTopNavItem, Page as CorePage, PageProps as CorePageProps } from "am-tax-fe-core";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { ApplicationRole, RoleNames, useHasAccess } from "../hooks";
import { MenuItem } from "@chakra-ui/react";
import { useClientEngagements, useEngagement, useProject } from "../api";
import { IconUserCircle, IconSettings } from "@tabler/icons-react";
import { AccessStatus } from "../enums/AccessStatus.ts";

export type PageProps = PropsWithChildren<Omit<CorePageProps, "location" | "menuItems">> & { subTitle?: string | undefined };

export const Page: FC<PageProps> = ({ children, subTitle, ...pageProps }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { identity } = useHasAccess();

    const isDeveloper = useMemo(() => (identity?.roles ?? []).map(r => RoleNames[r]).includes(RoleNames[ApplicationRole.developer]), [identity?.roles]);
    const menuItems = useMemo(() => {
        const items = [
            <MenuItem
                key={"myAccount"}
                icon={<IconUserCircle />}
                onClick={() => {
                    navigate("/myAccount");
                }}
            >
                My Account
            </MenuItem>,
        ];
        if (isDeveloper) {
            items.push(
                <MenuItem key={"profile"} icon={<IconSettings />} onClick={() => navigate("/profile")}>
                    Profile Settings
                </MenuItem>,
            );
        }
        return items;
    }, [isDeveloper, navigate]);

    const matchesEngagement = useMatch("/engagement/:engagementId/*");
    const engagementId = matchesEngagement?.params?.engagementId;

    const matchesProject = useMatch("/engagement/:engagementId/project/:projectId/*");
    const projectId = matchesProject?.params?.projectId;

    const engagementQuery = useEngagement(engagementId, projectId);
    const engagement = engagementQuery.data;

    const projectQuery = useProject(engagementId, projectId);
    const project = projectQuery.data;

    // get engagement for project
    const clientId = engagement?.clientId || project?.clientId;
    const { data } = useClientEngagements(clientId);
    const clientEngagement = data?.engagements?.find(x => x.engagementId === engagementId);

    const breadcrumbs: ClickableTopNavItem[] = [];

    let engagementBreadcrumb: ClickableTopNavItem | undefined;

    if (engagement) {
        engagementBreadcrumb = {
            name: `${engagement.projectCode}-${engagement.name}`,
            onClick: () => navigate(`/engagement/${engagement.engagementId}/dashboard`),
        };
    } else if (project && clientEngagement) {
        engagementBreadcrumb = {
            name: `${clientEngagement.projectCode}-${clientEngagement.name}`,
            onClick: () => {
                if (clientEngagement.accessStatus === AccessStatus.Accepted) navigate(`/engagement/${clientEngagement.engagementId}/dashboard`);
            },
        };
    }

    if (engagementBreadcrumb) {
        breadcrumbs.push(engagementBreadcrumb);
    }

    let projectBreadcrumb: ClickableTopNavItem | undefined;
    if (project) {
        projectBreadcrumb = {
            name: `${project.projectCode}-${project.name}`,
            onClick: () => navigate(`/engagement/${project.engagementId}/project/${project.projectId}/dashboard`),
        };
    }
    if (projectBreadcrumb) {
        breadcrumbs.push(projectBreadcrumb);
    }

    useEffect(() => {
        const isDevelopment = import.meta.env.VITE_ENGAGE_ENV === "development";
        const domainScript = isDevelopment ? import.meta.env.VITE_ONE_TRUST_DEV_DOMAIN_ID : import.meta.env.VITE_ONE_TRUST_PROD_DOMAIN_ID;
        console.log(domainScript);
        // Create and append the first script
        const script1 = document.createElement("script");
        script1.type = "text/javascript";
        script1.src = `https://cdn.cookielaw.org/consent/${domainScript}/OtAutoBlock.js`;
        document.head.appendChild(script1);

        // Create and append the second script
        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        script2.setAttribute("data-domain-script", domainScript!);
        document.head.appendChild(script2);

        // Create and append the inline script
        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.innerHTML = "function OptanonWrapper() { }";
        document.head.appendChild(script3);

        // Cleanup function to remove the scripts when the component unmounts
        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
            document.head.removeChild(script3);
        };
    }, []);

    return (
        <CorePage
            location={location}
            topNavProps={{
                buildNumber: import.meta.env.VITE_BUILD_NUMBER,
                breadcrumbs,
                subTitle,
            }}
            menuItems={menuItems}
            {...pageProps}
        >
            {children}
        </CorePage>
    );
};
